import Image from 'next/image';
import Link from 'next/link';
import storage from '@/shared/utils/storage';
import { findLast, isEmpty } from 'lodash';
import { App, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { CAMPAIGN_DATA, EMAIL_REGEX, CREATE_LOGIN_TOKEN, SIGN_UP_HOMEPAGE_INTEREST } from '@/shared/constants';
import { CloseOutlined } from '@/components/Common/Icons';
import { encode } from 'js-base64';
import {
  getStoreDetailsWithHandle,
  logCommonViewAnonymous,
  loginWithEmail,
  logUserRegister,
  registerStore,
  sendEventAnalyses,
  webToolsLink,
} from '@/lib/service';
import { useAsyncFn } from 'react-use';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useConfigProvider, useCreateAccountProvider } from '@/lib/context';
import queryString from 'query-string';
import { ParamKey } from '@/shared/types/url-params';
import { usePathname } from 'next/navigation';
import { storeMeBrandKit } from '@/lib/service/business-kits';
import { businessPDF, utilSendSlackMessage } from '@/lib/service/business-kits/common';
import { getSlackItemList } from '@/shared/utils/slack';
import { SlackChannel } from '@/shared/types/business-kits/api/common/util/send-slack-message';
import { EventName, EventSource } from '@/shared/constants/event';
import { useRouter } from 'next/router';
import { RegisterCustomPackagingParams } from '@/shared/types/featured-packaging';
import { StoreAccountType } from '@/shared/types/store';
import './index.less';
import { useHistoryList } from '@/lib/context/HistoryListProvider';

interface IProps {
  showCloseIcon: boolean;
  hideLogInLink?: boolean;
  subtitle?: string;
  signupRedirect?: string;
  docLink?: string;
  customPackagingParams?: Partial<RegisterCustomPackagingParams>;
  referrer?: string;
  email?: string;
  eventSource?: string;
}

export default function StoreSignupDialog({
  showCloseIcon,
  subtitle,
  signupRedirect,
  docLink,
  hideLogInLink = false,
  customPackagingParams,
  referrer,
  email,
  eventSource,
}: IProps) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { isMobile } = useConfigProvider();
  const { hideSignupDialog, showSendEmailModal } = useCreateAccountProvider();
  const [loading, setLoading] = useState<boolean>(false);

  const [queryState] = useAsyncFn(getStoreDetailsWithHandle, []);
  const pathname = usePathname();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const brandNameRef = useRef(null);
  const websiteRef = useRef(null);

  const { asPath } = useRouter();
  const { historyList } = useHistoryList();

  const getLoginUrl = useCallback(
    (token: string) => {
      if (!isEmpty(signupRedirect)) {
        return queryString.stringifyUrl({
          url: `${webToolsLink()}${signupRedirect}`,
          query: {
            [ParamKey.SHOW_ONBOARDING]: true,
            [ParamKey.TOKEN]: encode(token),
          },
        });
      } else {
        return queryString.stringifyUrl({
          url: `${webToolsLink()}/login`,
          query: {
            [ParamKey.TOKEN]: encode(token),
          },
        });
      }
    },
    [signupRedirect],
  );

  const autoLoginToAccount = useCallback(
    async (email: string, password: string) => {
      try {
        const res = await loginWithEmail({
          username: email,
          password: password,
          userType: 'STORE',
        });
        const token = res.token;
        storage.set(CREATE_LOGIN_TOKEN, token);
        /* 
         If a user tries out our 'create brand kits' feature before signing up, 
         we'll tie their anonymous work to their new account. 
         After they register, they'll get an email with their brand kits,
         and a pop-up to let them know the email was sent.
      */
        const pdfLink = businessPDF().get();
        if (!isEmpty(pdfLink)) {
          await storeMeBrandKit(pdfLink);
          utilSendSlackMessage({
            text: getSlackItemList([['Creator signed up from public brand kit', email]]).join('\n'),
            channel: SlackChannel.BRANDKIT,
          });
        }
        if (!isEmpty(docLink) || !isEmpty(customPackagingParams)) {
          hideSignupDialog();
          showSendEmailModal({ url: getLoginUrl(token) });
        } else {
          window.location.href = getLoginUrl(token);
        }
      } catch (e) {
        message.error('Sorry, there was a problem logging you into your account.');
      } finally {
        setLoading(false);
      }
    },
    [customPackagingParams, docLink, getLoginUrl, hideSignupDialog, message, showSendEmailModal],
  );

  const registerAccount = useCallback(
    async (values: any) => {
      const tagMap = {
        '/platform/sms-marketing': 'SMS_sign_up',
        '/platform/email-capture': 'Email_capture_sign_up',
        '/platform/analytics': 'Analytics_sign_up',
      };
      const campaignData = storage.get(CAMPAIGN_DATA);
      const interest = storage.get(SIGN_UP_HOMEPAGE_INTEREST);
      if (tagMap[pathname]) {
        if (campaignData && !campaignData.campaignSource) {
          campaignData.campaignSource = tagMap[pathname];
        }
      }
      const params = {
        ...values,
        fullName: `${values['firstName']} ${values['lastName']}`,
        firstName: values['firstName'],
        lastName: values['lastName'],
        title: values['brandName'],
        businessName: values['brandName'],
        hearFrom: values['hearFrom'],
        website: values['website'],
        utm: campaignData
          ? {
              source: campaignData.campaignSource,
              medium: campaignData.campaignMedium,
              campaign: campaignData.campaign,
              content: campaignData.campaignContent,
            }
          : tagMap[pathname]
            ? { source: tagMap[pathname] }
            : undefined,
        homepageInterests: interest,
        docLink,
        customPackagingParams,
        referer: asPath,
        accountType: StoreAccountType.UNDECIDED,
      };
      try {
        setLoading(true);
        const response = await registerStore(params);
        if (response?.userId) {
          logUserRegister(response, isMobile);
          storage.del(SIGN_UP_HOMEPAGE_INTEREST);
        }
        if (eventSource) logCommonViewAnonymous(eventSource.toUpperCase() as EventSource, values['email'], isMobile);
        await autoLoginToAccount(values['email'], values['password']);
        sendEventAnalyses({
          eventName: EventName.COMMON_VIEW,
          commonType: EventSource.USER_REGISTER_FORM,
          commonContent: {
            fullName: `${values['firstName']} ${values['lastName']}`,
            firstName: values['firstName'],
            lastName: values['lastName'],
            hearFrom: values['hearFrom'],
            accountName: values['brandName'],
            email: values['email'],
          },
          sourceClient: isMobile ? 'mobile_web' : 'desktop_web',
          field1: showCloseIcon,
          field2: showCloseIcon ? asPath : findLast(historyList, (path) => path !== asPath) || asPath,
        });
      } catch (error) {
        message.error('This account exists, please retry with a different email.');
      } finally {
        setLoading(false);
      }
    },
    [
      asPath,
      autoLoginToAccount,
      customPackagingParams,
      docLink,
      eventSource,
      historyList,
      isMobile,
      message,
      pathname,
      showCloseIcon,
    ],
  );

  const handleSubmitCreateAccount = useCallback(
    (values: any) => {
      if (isEmpty(values['brandName'])) {
        message.error('Please enter your brand name.');
        brandNameRef.current.focus();
        return;
      }
      if (isEmpty(values['firstName'])) {
        message.error('Please enter your first name.');
        firstNameRef.current.focus();
        return;
      }
      if (isEmpty(values['lastName'])) {
        message.error('Please enter your last name.');
        lastNameRef.current.focus();
        return;
      }
      if (isEmpty(values['email'])) {
        message.error('Please enter your business e-mail.');
        emailRef.current.focus();
        return;
      }
      if (!EMAIL_REGEX.test(values['email'].trim())) {
        message.error('Please enter a valid e-mail.');
        emailRef.current.focus();
        return;
      }
      if (isEmpty(values['password']) || values['password'].length < 6) {
        message.error('Please enter a password that is at least 6 characters in length.');
        passwordRef.current.focus();
        return;
      }
      if (!values['acceptTerms']) {
        message.error("Please accept Pietra's Terms and Conditions before proceeding.");
        return;
      }

      registerAccount(values);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, registerAccount, queryState.error],
  );

  useEffect(() => form.resetFields(), [form, referrer]);

  useEffect(() => {
    if (!email) return;
    setTimeout(() => form.setFieldsValue({ email }), 1001); // Slightly later than the time when automatically fills in
  }, [email, form]);

  return (
    <div className="store-signup-dialog">
      {showCloseIcon && (
        <CloseOutlined
          className="close-dialog"
          style={{ color: '#808080', fontSize: 24 }}
          onClick={() => hideSignupDialog()}
        />
      )}
      <div className="store-signup-dialog-form-wrap">
        <div className="title-container">
          <Link href={'/'} className="logo">
            <Image src="/images/footer-logo.png" width={92} height={24} alt="Pietra" title="Pietra" loading="eager" />
          </Link>
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
        <Form
          form={form}
          layout={'vertical'}
          requiredMark={false}
          initialValues={{ acceptTerms: false, hearFrom: referrer === 'pietra' ? 'PIETRA_TEAM_CONTACTED_ME' : null }}
          onFinish={handleSubmitCreateAccount}
          onValuesChange={() => {}}
          autoComplete={'off'}
        >
          <Form.Item hidden name="hearFrom">
            <Input />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="email" label="Business Email">
                <Input ref={emailRef} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="password" label="Password">
                <Input.Password ref={passwordRef} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="firstName" label="First name">
                <Input ref={firstNameRef} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="lastName" label="Last name">
                <Input ref={lastNameRef} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Account name" name="brandName">
            <Input ref={brandNameRef} />
          </Form.Item>
          <Form.Item label="Website (optional)" name="website">
            <Input ref={websiteRef} />
          </Form.Item>
          <Form.Item className="accept-terms" name="acceptTerms" valuePropName="checked">
            <Checkbox>
              <span className="utility-small">
                I accept Pietra&apos;s{' '}
                <Link
                  href="https://docs.google.com/document/d/1xyqmahqh73t9l9xV2VixOFbcli58saOktL_Ppn2RWyE"
                  target="_blank"
                >
                  Terms and Conditions.
                </Link>
              </span>
            </Checkbox>
          </Form.Item>
          <Form.Item className="submit-button">
            <Button type="primary" loading={loading} htmlType="submit" className="btn" block size={'large'}>
              {loading ? 'Creating account...' : 'Create account'}
            </Button>
            {!hideLogInLink && (
              <span className="log-in-link">
                Already have an account?{' '}
                <a href={webToolsLink() + (signupRedirect || '')} target="_blank" rel="noreferrer">
                  Log in.
                </a>
              </span>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
