import dayjs from 'dayjs';
import storage from '@/shared/utils/storage';
import { IxParams } from '@/shared/types/typings';
import { Post } from '../types/blog';
import { asDate, asText } from '@prismicio/helpers';
import { ANONYMOUS_USER_ID } from '../constants';
import isString from 'lodash/isString';

export * from './tools';

export const isMobileByUserAgent = (userAgent: string) => {
  return Boolean(userAgent && userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
};

export const isStaging = process.env.NEXT_PUBLIC_ENV !== 'production';

export const isS3Image = (url: string) => {
  return (url?.indexOf('static.pietrastudio.com') > -1 || url?.indexOf('carbonclub.s3.amazonaws.com') > -1) ?? false;
};

export const isPrismicImage = (url: string) => {
  return url.indexOf('images.prismic.io') > -1;
};

export function imgix(v: string, params?: IxParams) {
  if (!v) return '';

  const isPrismic = isPrismicImage(v);
  if (!isS3Image(v) && !isPrismic) return v;

  const url = new URL(v);
  if (isPrismic) {
    url.searchParams.delete('auto');
  } else {
    url.host = 'pietra.imgix.net';
  }

  if (!params?.auto) {
    url.searchParams.set('auto', 'compress,format');
  }

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }

  return url.toString();
}

type transformType = 'pico' | 'icon' | 'thumb' | 'small' | 'compact' | 'medium' | 'large' | 'grande' | '1024' | '2048';

/**
 * @link https://www.evernote.com/l/AT59JlZLi4xFW72l5E6dvv2Jtt8eHEOPbrY
 * @param url
 * @param size
 */
export function imageTransform(url: string, size: transformType | string) {
  if (typeof url !== 'string') return url;

  const pietraS3SizeKeys = ['compact', 'large', 'grande', '1024'];
  const shopifySizeKeys = ['pico', 'icon', 'thumb', 'small', 'compact', 'medium', 'large', 'grande', '1024', '2048'];
  if (isS3Image(url)) {
    if (!pietraS3SizeKeys.includes(size)) return url;
    const regex = /(\/public.*?\/)/;
    return url.replace(regex, `/public_${size}/`);
  } else if (typeof size === 'string') {
    size = size.toLowerCase();
    if (!shopifySizeKeys.includes(size)) return url;
    if (['1024', '2048'].includes(size)) {
      size = `${size}x${size}`;
    }
  } else if (typeof size === 'number') {
    if (!(size > 0)) return url;
  } else {
    return url;
  }

  const pathArray = url.split('/');
  if (!/shopify\.com/.test(pathArray[2])) return url;

  const lastIndex = pathArray.length - 1;
  const tmpArray = pathArray[lastIndex].split('.');

  if (!/^(jpg|png|jpeg|webp)/i.test(tmpArray[1])) return url;

  const basename = tmpArray[0].replace(RegExp(`_(${shopifySizeKeys.join('|')}|\\d+x\\d+)$`, 'ig'), '');
  tmpArray[0] = basename + `_${size}`;
  pathArray[lastIndex] = tmpArray.join('.');
  return pathArray.join('/');
}

export const formatDate = (dateString: string | Date | null): string => {
  return dayjs(dateString).format('MMMM DD, YYYY');
};

export const formatPost = (post: any): Post => {
  return {
    id: post.id,
    url: post.url.replace('/blog', `/blog/${post?.data?.category}`.toLowerCase()),
    uid: post.uid,
    type: post.type,
    href: post.href,
    tags: post.tags,
    first_publication_date: formatDate(asDate(post.first_publication_date)) || '',
    last_publication_date: formatDate(asDate(post.last_publication_date)) || '',
    slugs: post.slugs,
    linked_documents: post.linked_documents,
    lang: post.lang,
    alternate_languages: post.alternate_languages,
    data: {
      category: post.data.category,
      content: asText(post.data.content) || '',
      created_at_date: post.data.created_at || '',
      created_at: formatDate(asDate(post.data.created_at)) || '',
      image: post.data.image,
      seo_description: asText(post.data.seo_description) || '',
      seo_title: asText(post.data.seo_title) || '',
      slug: post.data.slug,
      subtitle: asText(post.data.subtitle) || '',
      title: asText(post.data.title) || '',
      author: asText(post.data.author) || 'pietra studio',
      author_avator:
        post.data.author_avator.link_type === 'Any'
          ? {
              link_type: 'Web',
              url: 'https://static.pietrastudio.com/public/file_uploads/a7b4c3924bf586e63cecf17b2876ba62.jpg',
              target: '',
            }
          : post.data.author_avator,
      author_position: asText(post.data.author_position) || 'Head of Design, Pietra',
      reading_time: asText(post.data.reading_time) || '5',
    },
  };
};

export const createQAList = (text: string, category: string, titleKey: string, valueKey: string) => {
  const parsedText = text.split('\n');
  const faqs = [];
  for (let i = 0; i < parsedText.length - 1; i += 2) {
    faqs.push({
      [titleKey]: parsedText[i],
      [valueKey]: parsedText[i + 1],
      category,
    });
  }
  return faqs;
};

export const generateUniqueSessionId = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // Use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const setAnonymousUser = () => {
  const id = storage.get(ANONYMOUS_USER_ID);
  if (id) return id;

  storage.set(ANONYMOUS_USER_ID, generateUniqueSessionId());
  return storage.get(ANONYMOUS_USER_ID);
};

const numberFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const numberFormatNoFractionalDigits = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const formatCurrency = (value: number, containsFractionalDigits = true) => {
  return containsFractionalDigits || !Number.isInteger(value)
    ? numberFormat.format(value)
    : numberFormatNoFractionalDigits.format(value);
};

export function numberWithCommas(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const isAssetTypePdf = (url: string) => {
  return url && url.endsWith('.pdf');
};

export const isAssetTypePsd = (url: string) => {
  return url && url.endsWith('.psd');
};

export const isAssetTypeAnImage = (url: string) => {
  // Here we are making the assumption that "product-templates" is an image. This is a printful-hosted image.
  return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff', 'jfif', 'product-templates'].some(
    function (v) {
      return url?.indexOf(v) >= 0;
    },
  );
};

export function getFileSuffix(url: string) {
  if (!url || !isString(url)) {
    return '';
  }
  const temp = url.split('.');
  return temp[temp.length - 1].toLowerCase();
}

export const convertToUTC = (date: string) => {
  const beijingTime = new Date(date);

  return beijingTime.toISOString();
};

export const parseMoney = (moneyStr: string): number => {
  // Remove whitespace from both ends of the string
  moneyStr = moneyStr.trim();
  // Check if it contains a unit and convert it to the corresponding multiplier
  let multiplier = 1;
  if (moneyStr.endsWith('K')) {
    multiplier = 1000; // 1K = 1000 yuan
    moneyStr = moneyStr.slice(0, -1); // Remove 'K'
  } else if (moneyStr.endsWith('M') || moneyStr.endsWith('M+')) {
    multiplier = 1000000; // 1M = 1000000 yuan
    moneyStr = moneyStr.slice(0, -1); // Remove 'M'
  }
  // Convert the remaining part to a number
  let money = parseFloat(moneyStr);
  // If the conversion fails, return NaN
  if (isNaN(money)) {
    return NaN;
  }
  // Return the final amount (in yuan)
  return money * multiplier;
};

export const isRangeGreaterOrEqualThanFixed = (rangeStr: string, fixedStr: string) => {
  // Remove currency symbols and handle 'k', 'M' suffixes
  function parseMoneyStr(str: string) {
    str = str.replace('$', '').toUpperCase(); // Remove $
    return parseMoney(str); // Directly convert the string to a floating-point number
  }

  // Parse the range and fixed value
  const [rangeStartStr = '', rangeEndStr = ''] = rangeStr.split('-');
  const fixedValue = parseMoneyStr(fixedStr);
  const rangeStart = parseMoneyStr(rangeStartStr);
  const rangeEnd = parseMoneyStr(rangeEndStr);

  // Only check if the upper limit of the range meets the condition
  return (!isNaN(rangeEnd) ? rangeEnd : rangeStart) > fixedValue;
};
