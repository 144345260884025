export enum VerticalName {
  ALL = 'All',
  PRODUCTS = 'Products',
  PACKAGING = 'Packaging',
  SERVICES = 'Services',
}

export enum SubCategoryName {
  ACTIVEWEAR = 'Activewear',
  APPAREL = 'Apparel',
  BABY = 'Baby & Toddler',
  BATH_BODY = 'Bath & Body',
  BEAUTY = 'Beauty',
  CANDLES = 'Candles',
  CANDY = 'Candy',
  CHOCOLATE = 'Chocolate',
  COFFEE_TEA = 'Coffee & Tea',
  CONDIMENTS = 'Condiments',
  CLOTHING = 'Clothing',
  FASHION_ACCESSORIES = 'Fashion Accessories',
  FIGURINES = 'Figurines',
  FITNESS = 'Fitness',
  FRAGRANCES = 'Fragrances',
  GAMES = 'Toys & Games',
  HAIR_CARE = 'Hair Care',
  HOME = 'Home Goods',
  JEWELRY = 'Jewelry',
  LEATHER = 'Leather Goods',
  MAKEUP = 'Makeup',
  MENS_APPAREL = 'Mens Apparel',
  MERCH = 'Merch',
  NAIL_CARE = 'Nail Care',
  PAPER = 'Paper Goods',
  PETS = 'Pets',
  SHOES = 'Shoes',
  SKATEBOARDS = 'Skateboards',
  SKINCARE = 'Skin Care',
  SMOKING = 'Smoking Accessories',
  STREETWEAR = 'Streetwear',
  SUNGLASSES = 'Sunglasses',
  WATCHES = 'Watches',
  WELLNESS = 'Wellness',
  WOMENS_APPAREL = "Women's Apparel",
  SWEETS = 'Sweets',
  SWIMWEAR = 'Swimwear',
  TECH = 'Tech Gadgets',
}

export enum PackagingCategory {
  CORRUGATED_MAILER_BOXES = 'Corrugated Mailer Boxes',
  SHIPPING_BOXES = 'Shipping Boxes',
  CARDSTOCK_PRODUCT_BOXES = 'Cardstock Product Boxes',
  POLYMAILERS = 'Polymailers',
  POLYBAGS = 'Polybags',
  TISSUE_PAPER = 'Tissue Paper',
  CUSTOM_SHOPPING_BAGS = 'Custom Shopping Bags',
  PAPER_MAILERS = 'Paper Mailers',
  CUSTOM_TAPE = 'Custom Tape',
  STICKERS = 'Stickers',
  PRODUCT_LABELS = 'Product Labels',
  NOTECARDS = 'Notecards',
  HANG_TAGS = 'Hang Tags',
  SOFT_PACKAGING = 'Soft Packaging',
  STANDUP_POUCHES = 'Standup Pouches',
  SPECIALTY_PACKAGING = 'Specialty Packaging',
  FOOD_AND_BEVERAGE = 'Food & Beverage',
}

export enum ServicesCategory {
  GRAPHIC_DESIGN = 'Graphic Design',
  WEB_DEVELOPMENT = 'Web Development',
  PHOTOGRAPHY = 'Photography',
  APPAREL_DESIGN = 'Apparel Design & Tech Packs',
  MARKETING = 'Marketing',
  BRAND_STRATEGY = 'Brand Strategy',
  SEARCH_ENGINE_MARKETING = 'Search Engine Marketing (SEM)',
  BUSINESS_CONSULTING = 'Business Consulting',
  SOCIAL_MEDIA = 'Social Media',
}

export type SourcingVertical = VerticalName | SubCategoryName;

export interface CategoryConfig {
  key: string;
  title: string;
  uniqueName: string;
  vertical: SourcingVertical;
  group?: string;
  subgroup?: string;
  image?: string;
  factoryCount?: number;
  topRankedSupplierIds?: number[];
  topRankedSearchTerms?: string[];
  brandKitSuggestedSupplierIds?: CategorySupplierSuggestion[];
  newSupplierIds?: CategorySupplierSuggestion[];
}

export interface CategorySupplierSuggestion {
  label: string;
  supplierId: number;
}
