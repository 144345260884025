import React from 'react';
import './index.less';

interface IProps {
  color?: string;
  width?: number;
  fullPage?: boolean;
}

const Spinner = ({ color, width, fullPage }: IProps) => {
  const spinner = (
    <svg className="loading-spinner" viewBox="0 0 100 100" style={{ width: width || 60 }}>
      <circle className="circlepath" cx="50" cy="50" r="40" />
      <circle className="atom1" cx="50" cy="10" r="8" style={{ fill: color || '#ff5c3c' }} />
    </svg>
  );
  if (fullPage) {
    return <div className="full-page">{spinner}</div>;
  }
  return spinner;
};

export default Spinner;
